@import "../../styles/variables.scss";

.menu-flags {
   color: $primary-white;
   font-weight: 500;
}
.menu-flags-class {
   background: rgba(20, 20, 20, 0.3);
   padding: 12px;
   outline: none;
   text-align: center;
}
#rfs-btn {
  border: none;
  color: white;
}
.ReactFlagsSelect-module_flagsSelect__2pfa2{
   ul{ background-color: rgb(0, 0, 0) }
   ul :hover{ background-color: rgb(62, 61, 61) }
   li{ color: white }
}
