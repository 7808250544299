@import "../../styles/variables.scss";

.bodyHome {
    .homeContainer {
        display: flex;
        flex-direction: row;
        .titleContainer {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 32px;
            h1 {
                color: $primary-white;
                width: 600px;
                font-size: 48px;
            }
            P {
                color: $primary-white;
                margin-top: 20px;
                width: 600px;
                font-size: 14px;
            }
            .buttonContainerBig {
                display: flex;
                max-width: 300px;
                margin-top: 20px;
                .space {
                    width: 100px;
                }
            }
        }

        .carContainer {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .titleCar {
                width: 100%;
                height: 500px;
                object-fit: contain;
            }
            .infoCar {
                background-color: #131313ab;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                clip-path: polygon(6% 0, 100% 0, 100% 100%, 0 100%);
                .controllerCar {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 90%;
                    padding: 16px 0px 16px 0px;

                    .infoCarPrice {
                        margin-left: 8px;
                        p {
                            color: white;
                            margin: 0px;
                        }
                    }

                    .space {
                        width: 1px;
                        background-color: white;
                        margin: 0px 10px 0px 10px;
                    }

                    .infoCarContainer {
                        display: flex;

                        .infoCarUSA {
                            p {
                                color: white;
                                margin: 0px;
                            }
                        }

                        .infoCarAM {
                            p {
                                color: white;
                                margin: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    .inventoryContainer {
        width: 99%;
        .titleInventor {
            padding: 32px 32px 0px 32px;
            h2 {
                color: $primary-white;
            }
            p {
                color: $primary-white;
                width: 600px;
            }
        }
        .lotList {
            padding: 32px;
            display: flex;
            overflow-x: auto;
            margin-left: -12px;
            margin-right: -12px;
            .lotListSpace {
                margin: 0px 12px 0px 12px;
            }
        }
        ::-webkit-scrollbar-track {
            background-color: #b3b3b3;
            border-radius: 3px;
            margin-left: 48px;
            margin-right: 32px;
        }
        ::-webkit-scrollbar {
            height: 8px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: rgb(110, 110, 110);
            border-radius: 3px;
        }
    }

    .videoContainer {
        padding: 32px;
        h1 {
            color: $primary-white;
            // font-size: 62px; //mobiel change size
            margin-bottom: 32px;
        }
        .videoFrame {
            border: 2px solid black;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .bodyHome {
        .homeContainer {
            display: flex;
            flex-direction: column;
            .titleContainer {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-left: 32px;
                h1 {
                    color: $primary-white;
                    width: 600px;
                    font-size: 48px;
                }

                P {
                    color: $primary-white;
                    margin-top: 20px;
                    width: 600px;
                    font-size: 14px;
                }

                .buttonContainerBig {
                    display: flex;
                    max-width: 300px;
                    margin-top: 20px;

                    .space {
                        width: 100px;
                    }
                }
            }

            .carContainer {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .titleCar {
                    //icon
                    width: 100%;
                    height: 500px;
                    object-fit: contain;
                }

                .infoCar {
                    background-color: #131313ab;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    clip-path: polygon(6% 0, 100% 0, 100% 100%, 0 100%);

                    .controllerCar {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 90%;
                        padding: 16px 0px 16px 0px;

                        .infoCarPrice {
                            margin-left: 8px;

                            p {
                                color: white;
                                margin: 0px;
                            }
                        }

                        .space {
                            width: 1px;
                            background-color: white;
                            margin: 0px 10px 0px 10px;
                        }

                        .infoCarContainer {
                            display: flex;

                            .infoCarUSA {
                                p {
                                    color: white;
                                    margin: 0px;
                                }
                            }

                            .infoCarAM {
                                p {
                                    color: white;
                                    margin: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .inventoryContainer {
            width: 99%;

            .titleInventor {
                padding: 32px;

                h2 {
                    color: $primary-white;
                }

                p {
                    color: $primary-white;
                    width: 100%;
                }
            }

            .lotList {
                padding: 32px;
                display: flex;
                overflow-x: auto;
                margin-left: -12px;
                margin-right: -12px;

                .lotListSpace {
                    margin: 0px 12px 0px 12px;
                }
            }
        }
        .videoContainer {
            padding: 32px;

            h1 {
                color: $primary-white;
                // font-size: 62px; //mobiel change size
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .bodyHome {
        .homeContainer {
            display: flex;
            flex-direction: column;
            .titleContainer {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px;
                h1 {
                    color: $primary-white;
                    text-align: center;
                    width: 90%;
                    font-size: 30px;
                }
                P {
                    color: $primary-white;
                    margin-top: 12px;
                    width: 90%;
                    font-size: 14px;
                    text-align: center;
                }
                .buttonContainerBig {
                    display: flex;
                    max-width: 300px;
                    margin-top: 20px;

                    .space {
                        width: 100px;
                    }
                }
            }

            .carContainer {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .titleCar {
                    //icon
                    width: 95%;
                    height: 300px;
                    object-fit: contain;
                }

                .infoCar {
                    background-color: #131313ab;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    clip-path: polygon(6% 0, 100% 0, 100% 100%, 0 100%);

                    .controllerCar {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 90%;
                        padding: 16px 0px 16px 0px;

                        .infoCarPrice {
                            margin-left: 8px;

                            p {
                                color: white;
                                margin: 0px;
                            }
                        }

                        .space {
                            width: 1px;
                            background-color: white;
                            margin: 0px 10px 0px 10px;
                        }

                        .infoCarContainer {
                            display: flex;

                            .infoCarUSA {
                                p {
                                    color: white;
                                    margin: 0px;
                                }
                            }

                            .infoCarAM {
                                p {
                                    color: white;
                                    margin: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .inventoryContainer {
            width: 95%;

            .titleInventor {
                padding: 32px;

                h2 {
                    color: $primary-white;
                }

                p {
                    color: $primary-white;
                    width: 100%;
                }
            }

            .lotList {
                padding: 32px;
                display: flex;
                overflow-x: auto;
                margin-left: -12px;
                margin-right: -12px;

                .lotListSpace {
                    margin: 0px 12px 0px 12px;
                }
            }
        }
        .videoContainer {
            padding: 32px;

            h1 {
                color: $primary-white;
                // font-size: 24px; //mobiel change size
            }
        }
    }
}
