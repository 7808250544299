@import "../../styles/variables.scss";
.ant-modal .ant-modal-content{
    // background-color: black;

    .ant-modal-footer{
                display: none;
                // background-color: red;
        }
        .ant-modal-title{
            // background-color: red;
        }
}

.formAdd{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 8px;

        ::placeholder {
            color: $placeholder-color !important;
            opacity: 0.7;
        }
        .formErrorMessage{
            color: $dust-red;
            text-align: center;
        }
}

.bodyUsers {
    padding: 0 32px 0px 32px;


    .usersHeader{
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 16px;
        h2{
            color: $primary-white;
            margin: 0px 0px 16px 0px
        }
        .buttonContainer{
            display: flex;
            width: 15%;
            height: 35px;
            .space{
                width: 4%;
                height: 10px;
            }
        }
    }
    .tableContainer{
        background-color: white;
    }
}

@media only screen and (max-width: 1200px) {
    .bodyUsers {
    padding: 0 32px 0px 32px;
    .usersHeader{
        .buttonContainer{
            display: flex;
            height: 40px;
            .space{
                width: 4%;
                height: 10px;
            }
        }
    }
    .tableContainer{

    }
}
}

@media only screen and (max-width: 600px) {
    .bodyUsers {
        padding: 0 16px 0px 16px;
    .usersHeader{
        display: block;
        .buttonContainer{
            padding: 2px;
            width: 100%;
            flex-wrap: wrap;
            height: 100%;
        }
    }
  }
}