@import "../../styles/variables.scss";

.footerBody {
  padding: 16px 32px 32px 32px;
  .footerBodyContainer {
    padding-top: 32px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .footerAboutContainer {
      flex: 4;
      .footerAboutContainerIcon {
        width: 122px;
        height: 20px;
        object-fit: contain;
        margin-bottom: 16px;
      }
      p:first-of-type {
        color: $primary-white;
        font-size: 16px;
        font-weight: 500;
      }
      p {
        color: rgba(255, 255, 255, 0.85);
        font-size: 14px;
        font-weight: 400;
        width: 80%; // mobile 100%
      }
    }
    .footerRightBox {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 600px;
      .footerCompanyContainer {
        p:first-of-type {
          color: $primary-white;
          font-size: 16px;
          font-weight: 500;
        }
        .headerSection {
          color: rgba(255, 255, 255, 0.85);
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
        }
        .headerSectionSelected {
          cursor: pointer;
          color: $dust-red;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .footerContactUsContainer {
        p:first-of-type {
          color: $primary-white;
          font-size: 16px;
          font-weight: 500;
        }
        p {
          color: rgba(255, 255, 255, 0.85);
          font-size: 14px;
          font-weight: 400;
        }
      }
      .footerSocialNetworkContainer {
        p:first-of-type {
          color: $primary-white;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
        }
        div {
          display: flex;
          align-items: center;
          cursor: pointer;
          .socialName {
            color: rgba(255, 255, 255, 0.85);
            font-size: 14px;
            font-weight: 400;
          }
          img {
            margin-right: 8px;
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
