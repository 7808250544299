@import "../../styles/variables.scss";

.bodyButton {
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .icon {
    margin-right: 8px;
  }
  :hover {
    cursor: pointer;
  }
  p {
    color: $primary-white;
    font-size: 16px;
    font-weight: 500;
  }
}
