@import "../../styles/variables.scss";

 .modalBodyAddCars{
    padding: 16px;
    border-radius: 8px;
    ::placeholder {
      color: $placeholder-color !important;
      opacity: 0.7;
    }
 }

.bodyCars {
    padding: 0 32px 0px 32px;
    .carsHeader{
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 16px;
        h2{
            color: $primary-white;
            margin: 0px 0px 16px 0px
        }
        .buttonContainer{
            display: flex;
            width: 15%;
            height: 35px;
            .space{
                width: 4%;
                height: 10px;
            }
        }
    }
    .tableContainer{
        background-color: rgb(255, 255, 255);
    }
}

@media only screen and (max-width: 1200px) {
    .bodyCars {
    padding: 0 32px 0px 32px;
    .carsHeader{
        .buttonContainer{
            display: flex;
            width: 25%;
            height: 40px;
            .space{
                width: 4%;
                height: 10px;
            }
        }
    }
}
}

@media only screen and (max-width: 600px) {
    .bodyCars {
        padding: 0 16px 0px 16px;
    .carsHeader{
        display: block;
        .buttonContainer{
            padding: 2px;
            width: 100%;
            flex-wrap: wrap;
            height: 100%;
        }
    }
  }
}