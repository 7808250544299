@import "./styles/variables.scss";

body {
  margin: 0px;
  min-height: 100vh;
}
html {
  margin: 0px auto;
  background-color: $black-color;
  max-width: 1920px;
  min-height: 100vh;
  font-family: Roboto, sans-serif;

}
