@import "../../styles/variables.scss";

.bodyLogin {
  display: flex;
  flex-direction: row;

  .loginImageContainer {
    flex: 2;

    .imageContainer {
      background-image: url("../../assets/africa.png");
      background-repeat: no-repeat;
      background-size: contain;
      margin-top: 60px;
      min-height: 90vh;
      display: flex;
      flex-direction: column;

      .carPrice {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .carContainer {
          margin-left: 20%;
          background-color: #11111147;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px;

          .carImage {
            margin-right: 8px;

            .iconImage {
              width: 50px;
              height: 50px;
            }
          }

          .carInfo {
            padding: 2;

            .carModel {
              color: $primary-white;
              font-size: 20px;
              font-weight: 500;
            }

            .mainText {
              color: $primary-white;
              opacity: 0.7;
              font-size: 14px;
              font-weight: 400;
              margin-top: 6px;

              .shippingCondition {
                color: $dust-red;
                font-weight: 500;
              }
            }
          }
        }
      }

      .titleContainer {
        display: flex;
        flex-direction: column;
        margin-left: 32px;

        .titleTextContainer {
          width: 580px;

          .title {
            color: $primary-white;
            font-size: 48px;
            font-weight: 500;
          }

          .subtitle {
            color: $primary-white;
            opacity: 0.7;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .loginInputContainer {
    flex: 1;
    padding: 16px;

    .loginContainerBack {
      margin-top: 10%;
      width: 450px;
      background-color: #11111147;
      padding: 30px;
      border-radius: 5px;

      .dontAccount {
        color: $primary-white;
        font-size: 16px;
        font-weight: 500;

        span {
          color: $dust-red;
          cursor: pointer;
        }
      }

      .signInTitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: $primary-white;
      }

      .forgotPassword {
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        color: $dust-red;
        margin-top: 24px;
        cursor: pointer;
        margin-bottom: 24px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .bodyLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;

    .loginImageContainer {
      display: none;
    }

    .loginInputContainer {
      flex: none;
      min-width: 60%;

      .loginContainerBack {
        width: auto;
        margin-top: 10%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .bodyLogin {
    display: flex;
    justify-content: center;
    align-items: center;

    .loginImageContainer {
      display: none;
    }

    .loginInputContainer {
      flex: none;
      min-width: 90%;

      .loginContainerBack {
        width: auto;
        margin-top: 10%;
      }
    }
  }
}
