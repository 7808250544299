@import "../../styles/variables.scss";

.overlay {
height: 100%;
width: 0;
position: fixed;
z-index: 1;
top: 0;
left: 0;
background-color: $black-color;
overflow-x: hidden;
transition: 0.9s;
.closebtn {
position: absolute;
top: -44px;
right: 25px;
font-size: 40px;
color: $primary-white;
}
.overlay-content {
margin: auto;
position: relative;
top: 5%;
width: 80%;
text-align: center;
min-height: 90%;
display: flex;
flex-direction: column;
justify-content: space-between;
.navList {
.headerSection {
color: $primary-white;
cursor: pointer;
margin: 6px auto;
border-radius: 5px;
padding: 16px;
}
.headerSectionSelected {
cursor: pointer;
font-weight: bold;
color: $dust-red;
margin: 6px auto;
border-radius: 5px;
padding: 16px;
}
.listContainer{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
div{
margin-left: -20px;
width: 150px;
display: flex;
align-items: center;
justify-content: space-between;
p{
flex: 1;
font-size: 14px;
color: $primary-white;
text-align: center;
}
img{
object-fit: contain;
width: 20px;
height: 20px;
}
}}}}}
