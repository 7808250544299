@import "../../styles/variables.scss";

.headerBody {
  background-color:$black-color;
  display: flex;
  flex-direction: row;
  height: 50px;
  padding: 0 32px 0 32px;
  margin-bottom: 64px;
  .headerIconContainer {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: row;
    .headerIconBurger {
      display: none;
      cursor: pointer;
    }
    .headerIconLogo {
      color: $primary-white;
      width: 122px;
      height: 20px;
    }
    .helperContainer {
      display: none;
    }
  }
  .loggedInMobile{
    display: none;
  }
  .headerMainContainer {
    display: flex;
    flex: 5;
    align-items: center;
    flex-direction: row;
    .headerSection {
      color: $primary-white;
      margin-left: 35px;
      cursor: pointer;
    }
    .headerSectionSelected {
      margin-left: 35px;
      cursor: pointer;
      font-weight: bold;
      color: $dust-red;
    }
  }

  .headerLoginContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    .headerLoginMenu{
      display: flex;
      justify-content: center;
      max-width: 220px;
      .userNameArrowIcon{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        img{
          width: 12px;
          height: 12px;
          margin-left: 8px;
          object-fit: contain;
        }
        p{
          color: $primary-white;
          margin-left: 40px;
          cursor: pointer;
          font-weight: bold;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
                  line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      .headerLoginMenuList{
        background-color: black;
        border: 1px solid $dust-red;
        padding: 1px;
        position: absolute;
        z-index: 100;
        top: 50px;
        margin-left: 40px;
        max-width: 200px;
        .listContainer{
          margin-top: 1px;
          cursor: pointer;
          background-color: rgb(56, 56, 57);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          &:hover {
          background-color: rgb(0, 0, 0);
          transition: 0.5s;
        }
          .iconListContainer{
            width: 20px;
            height: 16px;
            object-fit: contain
          }
          p{
            padding: 0;
            margin: 0px 0px 0px 12px;
            font-size: 14px;
            font-weight: 400;
            flex: 1;
            color: $primary-white;
          }
        }
      }
    }
    .headerLoginSelected {
      font-weight: bold;
      color: $dust-red;
      margin-left: 40px;
      cursor: pointer;
    }
    .headerLogin {
      color: $primary-white;
      margin-left: 40px;
      cursor: pointer;
    }
    .headerLanguage {
      margin-left: 40px;
      padding-top: 4px;
      padding-bottom: 3px;
      padding-left: 2px;
      padding-right: 2px;
      color: $primary-white;
      background-color: transparent;
      outline: none;
      border: none;
    }
    .headerSignup {
      color: $primary-white;
      margin-left: 40px;
    }
    .loginSpacer {
      color: $primary-white;
      margin-left: 12px;
      margin-right: -30px;
    }
    .headerPhone {
      color: $primary-white;
      margin-left: 40px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .headerBody {
    padding: 0 16px 0 16px;
    .headerIconContainer {
      justify-content: space-between;

      .headerIconBurger {
        display: block;
        object-fit: contain;
        width: 25px;
        height: 25px;
      }
      .helperContainer {
        display: block;
        width: 10px;
        height: 10px;
      }
    }
    .loggedInMobile{
      width: 35px;
      justify-content: center;
      display: flex;
      color: white;
      align-items: center;
    .iconLoggedin{
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }
    .headerMainContainer {
      display: none;
    }
    .headerLoginContainer {
      display: none;
      width: 400px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .headerBody {
    padding: 0 16px 0 16px;
    .headerIconContainer {
      .headerIconBurger {
        display: block;
        object-fit: contain;
        width: 25px;
        height: 25px;
      }
    }
     .loggedInMobile{
       display: flex;
       color: white
  }
    .headerMainContainer {
      display: none;
    }
    .headerLoginContainer {
      display: none;
      width: 400px;
    }
  }
}
