.carHeaderPart {
    background-color: #111111;
    border-radius: 10px;
    padding: 14px;
    .headerTitle {
        .title {
            color: white;
            font-size: 18px;
            font-weight: 500;
        }
        .subtitle {
            color: #9b9da0;
            font-size: 14px;
            font-weight: 400;
            margin-top: -16px;
        }
    }
    .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
            border-radius: 10px;
            object-fit: cover;
            width: 280px;
            height: 200px;
        }
    }
    .infoContainer {
        display: flex;
        justify-content: space-between;
        div p {
            color: white;
            font-size: 14px;
        }
    }
}
