@import "../../styles/variables.scss";

.footerBottomPanelBody {
    background-color: #161616a1;
    width: 100%;
    .footerBottomContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 16px 32px 16px 32px;
        div:first-of-type {
            p {
                cursor: pointer;
            }
            p:hover {
                color: $dust-red;
                transition: 1s;
            }
        }
        div {
            display: flex;
            flex-wrap: wrap;
            p {
                color: white;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}
