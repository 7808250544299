@import "../../styles/variables.scss";

.bodyServices {
    padding: 0 32px 0 32px;
    min-height: 65vh;
    .servicesContainer {
        h1 {
            color: $primary-white;
        }
        p {
            color: $primary-white;
        }
        .servicesListBody {
            padding: 0px 0px 32px 0px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}
