.ant-table-thead{ }
.ant-table-container{ }
.highlight-bottom-border > td {
    //border-bottom: solid 1px black !important
}
.highlight-top-border > td {
  border-top: solid 1px black !important;
}

.antDescriptionBody{
    .container{
        margin: 0px auto;
        width: 95%;
        .data{
            display: flex;
            div{ width: 21% }
        }
    }
}