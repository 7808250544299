@import "../../styles/variables.scss";

.bodyAbout {
    padding: 0 16px 32px 32px;
    .aboutMapContainer {
        display: flex;
        flex-direction: row;
        .titleContainer {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1 {
                margin-top: 0;
                color: $primary-white;
                width: 600px;
                font-size: 42px;
            }
            P {
                color: $primary-white;
                margin-top: 20px;
                width: 600px;
                font-size: 14px;
            }
            .buttonContainerBig {
                display: flex;
                max-width: 300px;
                margin-top: 20px;
                .space {
                    width: 100px;
                }
            }
        }
        .aboutContainer {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .titleMap {
                width: 100%;
                height: 500px;
                object-fit: contain;
            }
            .infoCar {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .controllerInfo {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 90%;
                    padding: 16px 0px 16px 0px;
                    .infoMapPrice {
                        margin-left: 8px;
                        p {
                            color: white;
                            margin: 0px;
                        }
                    }
                    .space {
                        width: 1px;
                        background-color: white;
                        margin: 0px 10px 0px 10px;
                    }
                    .infoCarContainer {
                        display: flex;
                        .infoCarUSA {
                            p {
                                color: white;
                                margin: 0px;
                            }
                        }
                        .infoCarAM {
                            p {
                                color: white;
                                margin: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    .ourTeam {
        margin-top: 32px;
        h1 {
            color: $primary-white;
            font-size: 30px;
        }
        .teamImageContainer {
            display: flex;
            .teamImage {
                border-radius: 14px;
                width: 99%;
                object-fit: contain;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .bodyAbout {
        .aboutMapContainer {
            display: flex;
            flex-direction: column;
            .titleContainer {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-left: 32px;
                h1 {
                    color: $primary-white;
                    width: 600px;
                    font-size: 42px;
                }
                P {
                    color: $primary-white;
                    margin-top: 20px;
                    width: 600px;
                    font-size: 14px;
                }
                .buttonContainerBig {
                    display: flex;
                    max-width: 300px;
                    margin-top: 20px;

                    .space {
                        width: 100px;
                    }
                }
            }
            .aboutContainer {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .titleMap {
                    width: 100%;
                    height: 500px;
                    object-fit: contain;
                }
                .infoCar {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .controllerInfo {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 90%;
                        padding: 16px 0px 16px 0px;
                        .infoMapPrice {
                            margin-left: 8px;
                            p {
                                color: white;
                                margin: 0px;
                            }
                        }
                        .space {
                            width: 1px;
                            background-color: white;
                            margin: 0px 10px 0px 10px;
                        }
                        .infoCarContainer {
                            display: flex;
                            .infoCarUSA {
                                p {
                                    color: white;
                                    margin: 0px;
                                }
                            }
                            .infoCarAM {
                                p {
                                    color: white;
                                    margin: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .bodyAbout {
        .aboutMapContainer {
            display: flex;
            flex-direction: column;
            .titleContainer {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px;
                h1 {
                    color: $primary-white;
                    text-align: center;
                    width: 90%;
                    font-size: 30px;
                }
                P {
                    color: $primary-white;
                    margin-top: 12px;
                    width: 90%;
                    font-size: 14px;
                    text-align: center;
                }
                .buttonContainerBig {
                    display: flex;
                    max-width: 300px;
                    margin-top: 20px;

                    .space {
                        width: 100px;
                    }
                }
            }
            .aboutContainer {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .titleMap {
                    width: 95%;
                    height: 300px;
                    object-fit: contain;
                }
                .infoCar {
                    background-color: #131313ab;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    // clip-path: polygon(6% 0, 100% 0, 100% 100%, 0 100%);
                    .controllerInfo {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 90%;
                        padding: 16px 0px 16px 0px;
                        .infoMapPrice {
                            margin-left: 8px;
                            p {
                                color: white;
                                margin: 0px;
                            }
                        }
                        .space {
                            width: 1px;
                            background-color: white;
                            margin: 0px 10px 0px 10px;
                        }
                        .infoCarContainer {
                            display: flex;
                            .infoCarUSA {
                                p {
                                    color: white;
                                    margin: 0px;
                                }
                            }
                            .infoCarAM {
                                p {
                                    color: white;
                                    margin: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
