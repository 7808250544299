@import "../../styles/variables.scss";

.servicesCardBody {
    width: 330px;
    margin-top: 16px;
    .container{
        padding: 16px 0px 16px 0px;
     .iconServices {
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-bottom: 8px;
    }
    h2 {
        color: $primary-white;
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }
    p {
        color: $primary-white;
        font-size: 14px;
        font-weight: 400;
      }
    }
}
