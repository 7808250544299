@import "../../styles/variables.scss";

.bodyEditor {
    padding: 0 32px 0px 32px;
    .editorHeader{
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:flex-end;
        flex-wrap: wrap;
        margin-bottom: 16px;
        .editorName{
            color: $primary-white;
            font-size: 24px;
        }
        .strongName{
            color: 'red'
        }
        .buttonContainer{
            display: flex;
            width: 15%;
            height: 35px;
        }
    }
    .tableContainer{
        background-color: white;
    }
}

@media only screen and (max-width: 600px) {
    .bodyEditor {
        .editorHeader{
            div{ width: 100% }
        .buttonContainer{
            margin-top: 16px;
            width: 100%;
            }
    }}
}