@import "../../styles/variables.scss";

.bodyCalculator {
  padding: 0 32px 32px 32px;
  .calculatorContainerTitle {
    h1 {
      color: white;
    }
    p {
      color: white;
    }
  }
  .calcLogic {
    margin-top: 64px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .calcContainer {
      flex: 1;
      .selectAuctions {
        p {
          color: white;
        }
        .selectAuctionsContainer {
          display: flex;
          justify-content: space-between;
          .notSelected {
            border: 1px solid white;
            width: 70px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
              object-fit: contain;
              width: 50px;
              height: 35px;
            }
          }
          .selectedDiv {
            border: 2px solid $dust-red;
            border-radius: 3px;
            width: 70px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
              object-fit: contain;
              width: 50px;
              height: 35px;
            }
          }
        }
      }
    }
    .space {
      width: 1px;
      background-color: white;
      margin: 0px 10% 0px 10%;
    }
    .resultContainer {
      color: white;
      flex: 1;
      .priceContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .resultBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .result {
          width: 45%;
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        .tax {
          width: 45%;
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
      .buttonContainer {
        margin-top: 15%;
        display: flex;
        justify-content: space-between;
      }
      .infoContainer {
        margin-top: 15%;

        .infoBody {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .bodyCalculator {
    padding: 0 32px 32px 32px;
    .calcLogic {
      display: block;
      .space {
        display: none;
      }
      .resultContainer {
        margin-top: 24px;
        display: block;
        .buttonContainer {
          margin-top: 5%;
        }
        .infoContainer {
          margin-top: 5%;
        }
        .resultBox {
          flex-wrap: wrap;
          .result {
            width: 100%;
          }
          .tax {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .bodyCalculator {
    padding: 0 16px 32px 16px;
    .calcLogic {
      .calcContainer {
        display: block;
      }
      .space {
        display: none;
      }
      .resultContainer {
        margin-top: 24px;
        padding: 0px;
        display: block;
        .resultBox {
          flex-wrap: wrap;
          .result {
            width: 100%;
          }
          .tax {
            width: 100%;
          }
        }
      }
    }
  }
}
