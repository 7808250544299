$primary-black: #000000;
$primary-white: #ffffff;
$dust-red: #f5222d;
$primary-pink: #f7cec8;
$input-border: #d9d9d9;
$black-color:#0F0F0F;
$table-header:#141414;
$sunset-orange: #FA8C16;
$sunrise-yellow: #FADB14;
$placeholder-color: #535454;

:export {
  primaryBlack: #000000;
  primaryWhite: #ffffff;
  dustRed: #f5222d;
  primaryPink: #f7cec8;
  inputBorder: #d9d9d9;
  black-color:#0F0F0F;
  table-header:#141414;
  sunsetOrange: #FA8C16;
  sunriseYellow: #FADB14;
  placeholder-color: #535454;

}
