@import "../../styles/variables.scss";

.selectorBody {
  .title {
    color: $primary-white;
    margin-bottom: 10px;
  }
  .selector {
    width: 100%;
    background-color: transparent;
    color: $primary-white;
    outline: none;
    padding: 12px;
    border-color: $input-border;
    appearance: none; 
    background-image: url("../../assets/arrow.png");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
  }
}
