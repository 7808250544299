@import "../../styles/variables.scss";

.bodyInputError {
  .input {
    border: 2px solid $dust-red;
    caret-color: $primary-white;
    border-radius: 2px;
    background: transparent;
    padding-left: 12px;
    width: 97%;
    height: 32px;
    color: $primary-white;
  }
}
.bodyInput {
  .inputTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $primary-white;
    margin-bottom: 8px;
  }
  .inputContainer {
    border: 1px solid $input-border;
    display: flex;
    border-radius: 2px;
    align-items: center;

    .input {
      outline: none;
      border: none;
      background: transparent;
      color: $primary-white;
      padding: 12px;
      color: $primary-white;
      flex: 1;
    }
    ::placeholder {
      color: $primary-white;
      opacity: 0.7;
    }
    .showPassIcon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
